import { ACTIVE, FAILED, PAUSED } from '../../cloud/js/classes/SubscriptionContract';

const SMALL_INPUT_LIMIT = 55;
const DEFAULT_INPUT_LIMIT = 255;
const DAYS_IN_WEEK = 7;
const DAYS_IN_LONG_MONTH = 31;
const DECIMALS_COUNT = 2;
const DATE_FORMAT = 'MMM D, YYYY';
const MAX_DELIVERY_COUNT = 50;
const LARGE_INPUT_LIMIT = 600;

const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const BULK_ACTION_PAUSE = 'pause';
const BULK_ACTION_RESUME = 'resume';
const BULK_ACTION_CANCEL = 'cancel';
const BULK_ACTIONS = [BULK_ACTION_PAUSE, BULK_ACTION_RESUME, BULK_ACTION_CANCEL];
const BULK_OPERATION_TARGET_STATUSES = {
  [BULK_ACTION_CANCEL]: [ACTIVE, PAUSED, FAILED],
  [BULK_ACTION_PAUSE]: [ACTIVE, FAILED],
  [BULK_ACTION_RESUME]: [PAUSED],
};

const PAYMENT_TITLE = ___('Payment');
const PLAN_TITLE = ___('Pricing plan');

const ACTUAL_FREE_PLAN_ID = 14;

const CODE_NOT_FOUND = 404;

const CODE_BAD_REQUEST = 400;

const ENTERPRISE = 'Enterprise';
const PRO = 'Pro';
const BASIC = 'Basic';
const GROWTH = 'Growth';

const PRICE_VERSION_V2 = 2;
const PRICE_VERSION_V3 = 3;

const HOURS_IN_DAY = 24;

export {
  DAYS_IN_WEEK,
  DATE_FORMAT,
  DECIMALS_COUNT,
  SMALL_INPUT_LIMIT,
  DEFAULT_INPUT_LIMIT,
  MAX_DELIVERY_COUNT,
  WEEK_DAYS,
  DAYS_IN_LONG_MONTH,
  BULK_ACTION_RESUME,
  BULK_ACTION_PAUSE,
  BULK_ACTION_CANCEL,
  BULK_ACTIONS,
  BULK_OPERATION_TARGET_STATUSES,
  LARGE_INPUT_LIMIT,
  PAYMENT_TITLE,
  PLAN_TITLE,
  ACTUAL_FREE_PLAN_ID,
  CODE_NOT_FOUND,
  CODE_BAD_REQUEST,
  ENTERPRISE,
  PRO,
  BASIC,
  GROWTH,
  PRICE_VERSION_V2,
  PRICE_VERSION_V3,
  HOURS_IN_DAY,
};
