import { PERCENTAGE } from "../../../cloud/js/classes/Discount";
import getSymbolFromCurrency from 'currency-symbol-map';
import {CENTS_IN_DOLLAR} from '../../../cloud/js/components/widget/Prices';

/**
 *
 * @param {number} value
 * @param {string} newMoneyFormat
 * @returns {string}
 */
function money (value, newMoneyFormat) {
  value = +value;
  const amount = value.toLocaleString('en-US', {minimumFractionDigits: 2});
  const amountNoDecimals = Math.round(value).toLocaleString('en-US');
  const amountNoDecimalsWithComma = amountNoDecimals.replace(',', '.');
  const amountNoDecimalsWithSpace = amountNoDecimals.replace(',', ' ');
  const amountWithApostrophe = amount.replace(',', '\'');
  const amountWithComma = amount
    .replace(',', '|')
    .replace('.', ',')
    .replace('|', '.');

  const moneyFormatMap = {
    amount_no_decimals_with_space_separator: amountNoDecimalsWithSpace,
    amount_no_decimals_with_comma_separator: amountNoDecimalsWithComma,
    amount_with_apostrophe_separator: amountWithApostrophe,
    amount_with_comma_separator: amountWithComma,
    amount_no_decimals: amountNoDecimals,
    amount: amount,
  };

  let result = newMoneyFormat;
  Object.entries(moneyFormatMap).forEach(([moneyFormat, val]) => {
    const pattern = '\\{\\{\\s*' + moneyFormat + '\\s*\\}\\}';
    const regExp = new RegExp(pattern, 'u');
    result = result.replace(regExp, val);
  });

  return result;
}

/**
 *
 * @param {number} value
 * @param {string} format
 * @param {string|null} shopCurrency
 * @param {string|null} currentCurrency
 * @returns {string}
 */
function moneyNewCurrency (value, format, shopCurrency = null, currentCurrency = null) {
  const moneyFormatted = money(value, format);
  const shopSymbol = getSymbolFromCurrency(shopCurrency);
  const currentSymbol = getSymbolFromCurrency(currentCurrency);

  return moneyFormatted.replace(shopCurrency, currentCurrency)
  .replace(shopSymbol, currentSymbol);
}

/**
 *
 * @param {Object} discount
 * @param {string|null} moneyFormat
 * @returns {string}
 */
function makeFormattedDiscount (discount, moneyFormat = null) {
  // eslint-disable-next-line no-template-curly-in-string
  moneyFormat = moneyFormat || '${{amount}}';

  if (discount.type === 'PERCENTAGE' || discount.type === PERCENTAGE) {
    return `${+discount.value}%`;
  }

  return money(+discount.value, moneyFormat);
}

/**
 * @param {number} price
 * @param {string} type
 * @param {number} value
 * @return {string}
 */
function applyDiscount (price, type, value) {
  const discountedPrice = type === PERCENTAGE ? price * (1 - value / CENTS_IN_DOLLAR) : price - value;
  // eslint-disable-next-line no-magic-numbers
  return Math.max(discountedPrice, 0).toFixed(2);
}

export { makeFormattedDiscount, moneyNewCurrency, money, applyDiscount };