//This file is autogenerated! Don't modify it!!

/* eslint-disable no-use-before-define */

import AbstractParent from './AbstractParent';


export default class SettingsCancellationFlow extends AbstractParent {

  /**
   * @type  {boolean}
   */
  show_reasons = false;

  /**
   * @type  {string}
   */
  universal_message = ___('Please select a reason for the cancellation of your subscription:');

  /**
   * @type  {string}
   */
  error_message = ___('Please select one of the reasons');

  /**
   * @type  {string[]}
   */
  reasons = [
    ___('Too expensive'),
    ___('The subscription was created by accident'),
    ___('Poor product quality'),
    ___('I already have more than I need'),
    ___('Other reasons'),
  ];

  /**
   * @type  {boolean}
   */
  show_comment_field = false;

  /**
   * @type  {string}
   */
  comment_placeholder = ___('You can leave a comment. Your feedback means a lot to us!');

  /**
   * It helps to make object structure
   * @type  {{string: *}}
   */
  get classMapping() {
    return {
    };
  };

  /**
   * @param  {object} properties
   */
  constructor(properties) {
    super();
    this.setProperties(properties);
  }
}