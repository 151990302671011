export const EMPTY_PATH = '/admin';
export const FAQ = 'https://recurringo.crisp.help/en/';
export const THEME_FAQ = 'https://recurringo.crisp.help/en/article/how-to-set-up-theme-app-extensions-in-rgo-subscribe-subscriptions-djfy2k/';
export const KLAVIYO_ACCOUNT_SECTION = 'https://www.klaviyo.com/account#api-keys-tab';
export const REQUEST_INTEGRATION_LINK = 'https://changelog.recurringo.com/ideas';
export const LEANBE = 'https://changelog.recurringo.com/announcements';
export const TERMS = 'https://spur-i-t.com/terms-and-conditions';
export const PRIVACY = 'https://spur-i-t.com/privacy-policy-recurring-order-subscription';
export const BOX_PAGE = 'https://{domain}/pages/{handle}';
export const APP_WALKTHROUGH_FAQ = 'https://recurringo.crisp.help/en/article/walkthrough-of-the-new-recurringo-ivkgmh/';
export const BOX_FAQ = 'https://recurringo.crisp.help/en/article/build-a-box-subscription-bundle-smsqhp/';
export const UPSELL_FAQ = 'https://recurringo.crisp.help/en/article/cart-upsell-90ftzo/';
export const FAILED_PAYMENT_FLOW_FAQ = 'https://recurringo.crisp.help/en/article/failed-payment-flow-1pivxoe/';
export const CANCELLATION_FLOW_FAQ = 'https://recurringo.crisp.help/en/article/subscription-cancellation-flow-for-customers-1dogvbt/';
export const FEEDBACK_FORM = 'https://forms.gle/TChu3Vw4ZRQsyGvJ7';
export const REDIRECT = `${window.location.origin}/redirect`;
export const INDEX = '/admin/home';
export const ONBOARDING = '/admin/onboarding';
export const PARTNER_APPS = '/admin/partner-apps';
export const PRICING = 'https://recurringo.com/pricing/';
export const CALENDLY = 'https://calendly.com/recurringo-support/demo-call';
export const HASH_SELECT_SUBSCRIPTION_TYPE_MODAL = '#modal-show=SelectSubscriptionTypeModal';
export const BFCM_2024 = 'https://www.makebecool.com/blog/how-can-you-enhance-the-customer-journey-during-bfcm-2024-to-make-the-most-of-the-season?utm_source=app&utm_medium=banner&utm_campaign=recurringo';

export const SUBSCRIPTIONS = {
  INDEX: '/admin/subscriptions',
  VIEW_FROM_STORE: '/subscriptions',
  VIEW: '/admin/subscriptions/:id',
};

export const RULES = {
  INDEX: '/admin/rules',
  CREATE: '/admin/rules/:type/create',
  EDIT: '/admin/rules/:id/edit',
  DUPLICATE: '/admin/rules/:id/duplicate',
};

export const SETTINGS = {
  INDEX: '/admin/settings',
  GENERAL: '/admin/settings/general',
  INTEGRATIONS: {
    INDEX: '/admin/settings/integrations',
    GOOGLE: '/admin/settings/integrations/google',
    KLAVIYO: '/admin/settings/integrations/klaviyo',
  },
  CUSTOMER: {
    INDEX: '/admin/settings/customer',
    LABELS: '/admin/settings/customer/labels',
  },
  NOTIFICATIONS: {
    INDEX: '/admin/settings/notifications',
    CUSTOMIZE: '/admin/settings/notifications/customize',
    EDIT: '/admin/settings/notifications/:type',
  },
  FAILED: '/admin/settings/failed',
  CANCELLATION: '/admin/settings/cancellation',
  DESIGN: '/admin/settings/design',
};

export const BOXES = {
  INDEX: '/admin/boxes',
  CREATE: '/admin/boxes/create',
  EDIT: '/admin/boxes/:id/edit',
  DUPLICATE: '/admin/boxes/:id/duplicate',
};

export const UPSELLS = {
  INDEX: '/admin/upsells',
  CART: '/admin/upsells/cart',
};

export const PAYMENTS = {
  INDEX: '/payments',
  CHARGE: '/payments/charge',
};

export const API = {
  SHOP: {
    ELIGIBLE: '/admin/api/shop/eligible',
    SHOW_BANNERS: '/admin/api/shop/banners/show',
    CLOSE_BANNERS: '/admin/api/shop/banners/close',
    STATS: '/admin/api/shop/stats',
    BOOST_SUBSCRIPTIONS_STEP_COMPLETE: '/admin/api/shop/boost-subscriptions-step/complete',
    BFCM: '/admin/api/shop/bfcm/shown',
  },
  ANALYTICS: {
    PRODUCTS: '/admin/api/anx/products',
    RULE_PRODUCTS: '/admin/api/anx/:rule/products',
    CHART: '/admin/api/anx/chart',
    TOTALS: '/admin/api/anx/stats',
    CHART_TOTALS: '/admin/api/anx/chart/stats',
    INTERNAL: {
      MIXPANEL: {
        APP_EMBED: '/admin/api/internal/analytics/app-embed',
        BANNER: '/admin/api/internal/analytics/banner',
        EVENT: '/admin/api/internal/analytics/mixpanel/event',
      },
      SLACK: '/admin/api/internal/analytics/slack',
    },
  },
  RULES: {
    GET: '/admin/api/rules',
    SAVE: '/admin/api/rules/:id',
    PAUSE: '/admin/api/rules/:id/pause',
    UNPAUSE: '/admin/api/rules/:id/unpause',
    DELETE: '/admin/api/rules/:id',
    RESTORE: '/admin/api/rules/:id/restore',
    DELETE_PRODUCT_VARIANTS: '/admin/api/rules/product-variants/delete',
  },
  SETTINGS: {
    GET: '/admin/api/settings',
    SAVE: '/admin/api/settings',
    SENDER_EMAIL: {
      SET: '/admin/api/settings/sender-email',
      UPDATE_STATUS: '/admin/api/settings/sender-email/update-status',
    },
    INTEGRATIONS: {
      GOOGLE: '/admin/api/settings/integrations/google',
      KLAVIYO: '/admin/api/settings/integrations/klaviyo',
    },
  },
  DESIGN: {
    SAVE: '/admin/api/design',
    CSS: '/admin/api/design/widget-css',
  },
  UPSELLS: {
    CART: {
      SAVE: '/admin/api/upsells/cart',
      PREVIEW: '/admin/api/upsells/cart/preview',
    },
  },
  SELECTORS: {
    PRODUCT: '/admin/api/selectors/product',
    COLLECTION: '/admin/api/selectors/collection',
    CART: '/admin/api/selectors/cart',
    THEME_PRODUCT: '/admin/api/selectors/theme-product',
    AUTO_SELECTORS: '/admin/api/selectors/auto-selectors',
  },
  NOTIFICATIONS: {
    TEST: '/admin/api/notifications/test-email',
    PREVIEW: '/admin/api/notifications/preview',
    UNSUBSCRIBE: '/admin/api/notifications/unsubscribe-page/:type',
  },
  SUBSCRIPTIONS: {
    GET: '/admin/api/subscriptions',
    LIST: '/admin/api/subscriptions/dashboard',
    PAUSE: '/admin/api/subscriptions/:id/pause',
    RESUME: '/admin/api/subscriptions/:id/resume',
    CANCEL: '/admin/api/subscriptions/:id/cancel',
    RESTORE: '/admin/api/subscriptions/:id/restore',
    EDIT_SCHEDULE: '/admin/api/subscriptions/:id/edit-schedule',
    SHIPPING: '/admin/api/subscriptions/:id/shipping',
    SHIPPING_ADDRESS: '/admin/api/subscriptions/:id/shipping-address',
    BULK: '/admin/api/subscriptions/bulk',
    BOXES: {
      UPDATE: '/admin/api/subscriptions/boxes/:box',
      DELETE: '/admin/api/subscriptions/boxes/:box',
    },
    PRODUCTS: {
      ADD: '/admin/api/subscriptions/:id/line-items',
      UPDATE: '/admin/api/subscriptions/:subscription/line-items/:product',
      DELETE: '/admin/api/subscriptions/:subscription/line-items/:product',
    },
  },
  FULFILLMENTS: {
    RESCHEDULE: '/admin/api/fulfillments/reschedule',
  },
  CUSTOMERS: {
    LIST: '/admin/api/customers',
  },
  EXPORT: {
    CUSTOMERS: '/admin/api/export/customers',
    SUBSCRIPTIONS: '/admin/api/export/subscriptions',
  },
  PRODUCTS: '/admin/api/products',
  ITEMS_PAGE: '/admin/api/products/page/:id',
  PC: {
    FIRST_INSTALL: '/admin/api/pc/first-install',
    CURRENT_PLAN: '/admin/api/pc/plan',
    PLANS: '/admin/api/pc/plans',
  },
  THEME: {
    INDEX: '/admin/api/themes',
  },
  ONBOARDING: {
    STATUS: '/admin/api/onboarding/status',
    PREVIEW: '/admin/api/onboarding/preview',
  },
  BOXES: {
    GET: '/admin/api/boxes',
    SAVE: '/admin/api/boxes/:id',
    PAUSE: '/admin/api/boxes/:id/pause',
    UNPAUSE: '/admin/api/boxes/:id/unpause',
    DELETE: '/admin/api/boxes/:id',
    RESTORE: '/admin/api/boxes/:id/restore',
    UPLOAD_IMAGE: '/admin/api/boxes/:id/upload-image',
    HANDLE: '/admin/api/boxes/:id/handle',
    HANDLES: '/admin/api/boxes/handles',
  },
  BILLING: {
    APPLY: '/admin/api/billing/:plan_id/apply',
  },
  BONUS: {
    APPLY: '/admin/api/bonus/apply',
  },
  PARTNER_APPS: '/admin/api/partner-apps',
};